<template>
  <v-data-table
      :headers="headers"
      :items="varsities"
      sort-by="calories"
      class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar
          flat
      >
        <v-toolbar-title>Varsity List</v-toolbar-title>
        <v-divider
            class="mx-4"
            inset
            vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-dialog
            v-model="dialog"
            max-width="500px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                icon
                color="primary"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
            >
              <v-icon color="blue">mdi-plus</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                      cols="12"
                  >
                    <v-text-field
                        v-model="editedItem.name"
                        label="Varsity name"
                    ></v-text-field>
                  </v-col>
                  <v-col
                      cols="12"
                  >
                    <v-file-input
                        v-model="photo"
                        label="Varsity cover photo"
                        clearable
                    ></v-file-input>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="blue darken-1"
                  text
                  @click="close"
              >
                Cancel
              </v-btn>
              <v-btn
                  color="blue darken-1"
                  text
                  @click="save"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="headline">Are you sure you want to delete this item?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.name="{ item }">
      <router-link :to="{name:'VarsityInfos',params:{varsityId:item.id}}">
        {{ item.name }}
      </router-link>
    </template>
    <template v-slot:item.photo="{ item }">
      <v-img max-width="100" :src="item.photo"></v-img>
    </template>
    <template v-slot:item.unit="{ item }">
      <v-btn text color="primary" :to="{name:'Units',params:{varsityId:item.id}}">Units</v-btn>
    </template>
    <template v-slot:item.users_count="{ item }">
      {{item.users_count}}
      <v-btn icon small @click="exportUsers(item.id)">
        <v-icon>mdi-cloud-download</v-icon>
      </v-btn>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
          small
          class="mr-2"
          @click="editItem(item)"
          color="blue"
      >
        mdi-pencil
      </v-icon>
      <v-icon
          small
          @click="deleteItem(item)"
          color="red"
      >
        mdi-delete
      </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn
          color="primary"
          @click="initialize"
      >
        Reset
      </v-btn>
    </template>
  </v-data-table>
</template>
<script>
import axios from "axios";

export default {
  data: () => ({
    dialog: false,
    dialogDelete: false,
    photo: null,
    headers: [
      {
        text: 'Photo',
        value: 'photo'
      },
      {
        text: 'Name',
        align: 'start',
        value: 'name',
      },
      {
        text: 'Units',
        value: 'unit'
      },
      {
        text: 'Favorite count',
        value: 'users_count',
      },
      {
        text: 'Actions',
        value: 'actions', sortable: false
      },
    ],
    varsities: [],
    editedIndex: -1,
    editedItem: {
      name: '',
    },
    defaultItem: {
      name: '',
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },
    form() {
      let formData = new FormData()
      if (this.editedIndex > -1) {
        formData.append('_method', 'PUT')
      } else {
        formData.append('varsity_category_id', this.$route.params.varsityCategoryId)
      }
      formData.append('name', this.editedItem.name)
      if (this.photo) {
        formData.append('photo', this.photo)
      }
      return formData
    }
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },

  created() {
    this.initialize()
  },

  methods: {
    initialize() {
      const url = 'admin/varsity?id=' + this.$route.params.varsityCategoryId
      axios.get(url).then((response) => {
        this.varsities = response.data.varsities
      }).catch(() => {

      })
    },

    editItem(item) {
      this.editedIndex = this.varsities.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.varsities.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      const url = 'admin/varsity/' + this.editedItem.id
      let editedIndex = this.editedIndex
      axios.delete(url).then(() => {
        this.varsities.splice(editedIndex, 1)
        this.closeDelete()
      })
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save() {
      if (this.editedIndex > -1) {
        const url = 'admin/varsity/' + this.editedItem.id
        let editedIndex = this.editedIndex
        axios.post(url, this.form).then((response) => {
          Object.assign(this.varsities[editedIndex], response.data)
        })
      } else {
        const url = 'admin/varsity'
        axios.post(url, this.form).then((response) => {
          this.varsities.push(response.data)
        })
      }
      this.close()
    },

    exportUsers($varsityId){
      const url = 'admin/varsity/' + $varsityId + '?type=export'
      axios.get(url, {
        headers:
            {
              'Content-Disposition': "attachment; filename=template.xlsx",
              'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            },
        responseType: 'arraybuffer',
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        let filename = 'users'
        link.setAttribute('download', filename + '.xlsx');
        document.body.appendChild(link);
        link.click();
      }).catch((error) => {
        console.log(error)
      })
    }
  },
}
</script>
